export const HomeEnJm = () => import('../../components/content/home/home-en-jm.vue' /* webpackChunkName: "components/home-en-jm" */).then(c => wrapFunctional(c.default || c))
export const HomeEn = () => import('../../components/content/home/home-en.vue' /* webpackChunkName: "components/home-en" */).then(c => wrapFunctional(c.default || c))
export const HomeEsCr = () => import('../../components/content/home/home-es-cr.vue' /* webpackChunkName: "components/home-es-cr" */).then(c => wrapFunctional(c.default || c))
export const HomeEsDo = () => import('../../components/content/home/home-es-do.vue' /* webpackChunkName: "components/home-es-do" */).then(c => wrapFunctional(c.default || c))
export const HomeEsGt = () => import('../../components/content/home/home-es-gt.vue' /* webpackChunkName: "components/home-es-gt" */).then(c => wrapFunctional(c.default || c))
export const HomeEsHn = () => import('../../components/content/home/home-es-hn.vue' /* webpackChunkName: "components/home-es-hn" */).then(c => wrapFunctional(c.default || c))
export const HomeEsPa = () => import('../../components/content/home/home-es-pa.vue' /* webpackChunkName: "components/home-es-pa" */).then(c => wrapFunctional(c.default || c))
export const HomeEsSv = () => import('../../components/content/home/home-es-sv.vue' /* webpackChunkName: "components/home-es-sv" */).then(c => wrapFunctional(c.default || c))
export const HomeEs = () => import('../../components/content/home/home-es.vue' /* webpackChunkName: "components/home-es" */).then(c => wrapFunctional(c.default || c))
export const VaccinesEn = () => import('../../components/content/vaccines/vaccines-en.vue' /* webpackChunkName: "components/vaccines-en" */).then(c => wrapFunctional(c.default || c))
export const VacunasEs = () => import('../../components/content/vacunas/vacunas-es.vue' /* webpackChunkName: "components/vacunas-es" */).then(c => wrapFunctional(c.default || c))
export const ChickenpoxEn = () => import('../../components/content/vaccines/chickenpox/chickenpox-en.vue' /* webpackChunkName: "components/chickenpox-en" */).then(c => wrapFunctional(c.default || c))
export const DiphtheriaEn = () => import('../../components/content/vaccines/diphtheria/diphtheria-en.vue' /* webpackChunkName: "components/diphtheria-en" */).then(c => wrapFunctional(c.default || c))
export const HepatitisAEn = () => import('../../components/content/vaccines/hepatitis-a/hepatitis-a-en.vue' /* webpackChunkName: "components/hepatitis-a-en" */).then(c => wrapFunctional(c.default || c))
export const HepatitisBEn = () => import('../../components/content/vaccines/hepatitis-b/hepatitis-b-en.vue' /* webpackChunkName: "components/hepatitis-b-en" */).then(c => wrapFunctional(c.default || c))
export const HibEn = () => import('../../components/content/vaccines/hib/hib-en.vue' /* webpackChunkName: "components/hib-en" */).then(c => wrapFunctional(c.default || c))
export const InfluenzaEn = () => import('../../components/content/vaccines/influenza/influenza-en.vue' /* webpackChunkName: "components/influenza-en" */).then(c => wrapFunctional(c.default || c))
export const MumpsEn = () => import('../../components/content/vaccines/mumps/mumps-en.vue' /* webpackChunkName: "components/mumps-en" */).then(c => wrapFunctional(c.default || c))
export const MeaslesEn = () => import('../../components/content/vaccines/measles/measles-en.vue' /* webpackChunkName: "components/measles-en" */).then(c => wrapFunctional(c.default || c))
export const PneumococcalDiseaseEn = () => import('../../components/content/vaccines/pneumococcal-disease/pneumococcal-disease-en.vue' /* webpackChunkName: "components/pneumococcal-disease-en" */).then(c => wrapFunctional(c.default || c))
export const PoliomyelitisEn = () => import('../../components/content/vaccines/poliomyelitis/poliomyelitis-en.vue' /* webpackChunkName: "components/poliomyelitis-en" */).then(c => wrapFunctional(c.default || c))
export const RotavirusEn = () => import('../../components/content/vaccines/rotavirus/rotavirus-en.vue' /* webpackChunkName: "components/rotavirus-en" */).then(c => wrapFunctional(c.default || c))
export const RubellaEn = () => import('../../components/content/vaccines/rubella/rubella-en.vue' /* webpackChunkName: "components/rubella-en" */).then(c => wrapFunctional(c.default || c))
export const TetanusEn = () => import('../../components/content/vaccines/tetanus/tetanus-en.vue' /* webpackChunkName: "components/tetanus-en" */).then(c => wrapFunctional(c.default || c))
export const WhoopingCoughEn = () => import('../../components/content/vaccines/whooping-cough/whooping-cough-en.vue' /* webpackChunkName: "components/whooping-cough-en" */).then(c => wrapFunctional(c.default || c))
export const DifteriaEs = () => import('../../components/content/vacunas/difteria/difteria-es.vue' /* webpackChunkName: "components/difteria-es" */).then(c => wrapFunctional(c.default || c))
export const EnfermedadNeumococicaEs = () => import('../../components/content/vacunas/enfermedad-neumococica/enfermedad-neumococica-es.vue' /* webpackChunkName: "components/enfermedad-neumococica-es" */).then(c => wrapFunctional(c.default || c))
export const HepatitisAEs = () => import('../../components/content/vacunas/hepatitis-a/hepatitis-a-es.vue' /* webpackChunkName: "components/hepatitis-a-es" */).then(c => wrapFunctional(c.default || c))
export const HepatitisBEs = () => import('../../components/content/vacunas/hepatitis-b/hepatitis-b-es.vue' /* webpackChunkName: "components/hepatitis-b-es" */).then(c => wrapFunctional(c.default || c))
export const HibEs = () => import('../../components/content/vacunas/hib/hib-es.vue' /* webpackChunkName: "components/hib-es" */).then(c => wrapFunctional(c.default || c))
export const InfluenzaEs = () => import('../../components/content/vacunas/influenza/influenza-es.vue' /* webpackChunkName: "components/influenza-es" */).then(c => wrapFunctional(c.default || c))
export const PaperasEs = () => import('../../components/content/vacunas/paperas/paperas-es.vue' /* webpackChunkName: "components/paperas-es" */).then(c => wrapFunctional(c.default || c))
export const PoliomielitisEs = () => import('../../components/content/vacunas/poliomielitis/poliomielitis-es.vue' /* webpackChunkName: "components/poliomielitis-es" */).then(c => wrapFunctional(c.default || c))
export const RotavirusEs = () => import('../../components/content/vacunas/rotavirus/rotavirus-es.vue' /* webpackChunkName: "components/rotavirus-es" */).then(c => wrapFunctional(c.default || c))
export const RubeolaEs = () => import('../../components/content/vacunas/rubeola/rubeola-es.vue' /* webpackChunkName: "components/rubeola-es" */).then(c => wrapFunctional(c.default || c))
export const SarampionEs = () => import('../../components/content/vacunas/sarampion/sarampion-es.vue' /* webpackChunkName: "components/sarampion-es" */).then(c => wrapFunctional(c.default || c))
export const TetanosEs = () => import('../../components/content/vacunas/tetanos/tetanos-es.vue' /* webpackChunkName: "components/tetanos-es" */).then(c => wrapFunctional(c.default || c))
export const TosFerinaEs = () => import('../../components/content/vacunas/tos-ferina/tos-ferina-es.vue' /* webpackChunkName: "components/tos-ferina-es" */).then(c => wrapFunctional(c.default || c))
export const VacunacionEnElAdultoEs = () => import('../../components/content/vacunas/vacunacion-en-el-adulto/vacunacion-en-el-adulto-es.vue' /* webpackChunkName: "components/vacunacion-en-el-adulto-es" */).then(c => wrapFunctional(c.default || c))
export const VaricelaEs = () => import('../../components/content/vacunas/varicela/varicela-es.vue' /* webpackChunkName: "components/varicela-es" */).then(c => wrapFunctional(c.default || c))
export const DepresionEn = () => import('../../components/content/diseases/central-nervous-system/depression/depresion-en.vue' /* webpackChunkName: "components/depresion-en" */).then(c => wrapFunctional(c.default || c))
export const EpilepsyEn = () => import('../../components/content/diseases/central-nervous-system/epilepsy/epilepsy-en.vue' /* webpackChunkName: "components/epilepsy-en" */).then(c => wrapFunctional(c.default || c))
export const BphEn = () => import('../../components/content/diseases/other-diseases/bph/bph-en.vue' /* webpackChunkName: "components/bph-en" */).then(c => wrapFunctional(c.default || c))
export const AllergicRhinitisEn = () => import('../../components/content/diseases/respiratory-diseases/allergic-rhinitis/allergic-rhinitis-en.vue' /* webpackChunkName: "components/allergic-rhinitis-en" */).then(c => wrapFunctional(c.default || c))
export const RinitisAlergicaEs = () => import('../../components/content/enfermedades/enfermedades-respiratorias/rinitis-alergica/rinitis-alergica-es.vue' /* webpackChunkName: "components/rinitis-alergica-es" */).then(c => wrapFunctional(c.default || c))
export const HpbEs = () => import('../../components/content/enfermedades/otras-enfermedades/hpb/hpb-es.vue' /* webpackChunkName: "components/hpb-es" */).then(c => wrapFunctional(c.default || c))
export const DepresionEs = () => import('../../components/content/enfermedades/sistema-nervioso-central/depresion/depresion-es.vue' /* webpackChunkName: "components/depresion-es" */).then(c => wrapFunctional(c.default || c))
export const EpilepsiaEs = () => import('../../components/content/enfermedades/sistema-nervioso-central/epilepsia/epilepsia-es.vue' /* webpackChunkName: "components/epilepsia-es" */).then(c => wrapFunctional(c.default || c))
export const HowToHelpARelativeOrAFriendWithDepressionEn = () => import('../../components/content/diseases/central-nervous-system/depression/related-posts/how-to-help-a-relative-or-a-friend-with-depression-en.vue' /* webpackChunkName: "components/how-to-help-a-relative-or-a-friend-with-depression-en" */).then(c => wrapFunctional(c.default || c))
export const LivingWithDepressionEn = () => import('../../components/content/diseases/central-nervous-system/depression/related-posts/living-with-depression-en.vue' /* webpackChunkName: "components/living-with-depression-en" */).then(c => wrapFunctional(c.default || c))
export const LivingWithEpilepsyEn = () => import('../../components/content/diseases/central-nervous-system/epilepsy/related-posts/living-with-epilepsy-en.vue' /* webpackChunkName: "components/living-with-epilepsy-en" */).then(c => wrapFunctional(c.default || c))
export const ComoAyudarAUnFamiliarOUnAmigoConDepresionEs = () => import('../../components/content/enfermedades/sistema-nervioso-central/depresion/articulos-relacionados/como-ayudar-a-un-familiar-o-un-amigo-con-depresion-es.vue' /* webpackChunkName: "components/como-ayudar-a-un-familiar-o-un-amigo-con-depresion-es" */).then(c => wrapFunctional(c.default || c))
export const ViviendoConDepresionEs = () => import('../../components/content/enfermedades/sistema-nervioso-central/depresion/articulos-relacionados/viviendo-con-depresion-es.vue' /* webpackChunkName: "components/viviendo-con-depresion-es" */).then(c => wrapFunctional(c.default || c))
export const ViviendoConEpilepsiaEs = () => import('../../components/content/enfermedades/sistema-nervioso-central/epilepsia/articulos-relacionados/viviendo-con-epilepsia-es.vue' /* webpackChunkName: "components/viviendo-con-epilepsia-es" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
